/* pages/blog/BlogPost.module.css */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

h2 {
  font-size: 25px;
}

h2, h3 {
  /* margin-top: 1.5rem;
  margin-bottom: 0.75rem; */
  font-weight: bold;
}

p {
  line-height: 1.6;
  /* margin-bottom: 0.75rem; */
  margin-top: 15px;
  margin-bottom:15px;
  font-size: 20px;
}

strong {
  font-weight: 300;
  font-style: italic;
}

ol, ul {
  margin: 0.75rem 0;
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

a {
  color: blue;
  text-decoration: underline;
}

.sticky {
  height: 80px; /* Fixed height for the navbar */
}

.nav-scrolled {
  background-color: black;
}

.nav-scrolled span,
.nav-scrolled a {
  color: white;
  font-size: 0.875rem;
}

.blur-effect {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); /* For Safari support */
}

.nav-scrolled button {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}

/* Add these new styles for smooth transitions */
.sticky > div {
  height: 80px;
  display: flex;
  align-items: center;
}

.sticky nav,
.sticky .max-w-[1200px] {
  height: 100%;
}

.sticky .flex {
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.nav-scrolled .flex {
  height: 60px;
}
